import React from "react";
import { StyleRoot } from 'radium';

const ProgressBar = (props) => {
    const { bgcolor, isVisible, completed } = props;

    const containerStyles = {
        height: 20,
        width: '90%',
        backgroundColor: "#DEE2E8",
        borderRadius: 50,
        margin: 50,
        marginTop:0,
        '@media (max-width: 768px)': {
            marginTop: 0,
            marginBottom: 15,
        },
    }

    const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        backgroundColor: bgcolor,
        transition: 'width 1s ease-in-out',
        borderRadius: 'inherit',
        textAlign: 'right',
    }

    const labelStyles = {
        padding: 5,
        color: 'white',
        fontWeight: 'bold',
    }


    return (
        <div style={{width: "100%", display: isVisible ? 'flex' : 'none'}}>
            <div style={containerStyles}>
                <div style={fillerStyles}/>
            </div>
        </div>
    );
};

export default ProgressBar;