const images = {
    demo: require('./demo_img.png'),
    webSmall: require('./web_small.png'),
    webSmallGray: require('./web_small_gray.png'),
    iosSmall: require('./iOS_small.png'),
    iosSmallGray: require('./iOS_small_gray.png'),
    androidSmall: require('./android_small.png'),
    androidSmallGray: require('./android_small_gray.png'),
    Web: require('./Web.png'),
    Android: require('./Android.png'),
    iOS: require('./iOS.png'),
    smallIcon: require('./Small.png'),
    mediumIcon: require('./Medium.png'),
    largeIcon: require('./Large.png'),
    mvpIcon: require('./MVP.png'),
    basicIcon: require('./Basic.png'),
    polishedIcon: require('./Polished.png'),
    emailPassSignUp: require('./Email-pass.png'),
    facebookSignUp: require('./facebook.png'),
    twitterSignUp: require('./twitter.png'),
    googleSignUp: require('./google.png'),
    linkedInSignUp: require('./linkedin.png'),
    githubSignUp: require('./github.png'),
    userInvitationsEmail: require('./userInvitationEmails.png'),
    multiTenantAccount: require('./multiTenantAccounts.png'),
    subdomains: require('./subdomains.png'),
    customWww: require('./customDomain.png'),
    dashboard: require('./dashboard.png'),
    activityFeed: require('./activityFeed.png'),
    mediaUploading: require('./uploadFiles.png'),
    fileUploading: require('./uploadFiles.png'),
    userProfiles: require('./userProfile.png'),
    transactionalEmails: require('./transactionalEmails.png'),
    tagsIcon: require('./tags.png'),
    ratingsIcon: require('./ratings.png'),
    mediaManipulation: require('./video_audio.png'),
    searchIcon: require('./textSearching.png'),
    iconDesign: require('./iconDesign.png'),
    cloudSyncing: require('./cloud.png'),
    deviceSensors: require('./deviceSensors.png'),
    barcodes: require('./barcode.png'),
    healthData: require('./healthData.png'),
    appleWatch: require('./appleWatch.png'),
    calendaring: require('./calendar.png'),
    mapData: require('./geolocation.png'),
    customMap: require('./customMap.png'),
    bookings: require('./booking.png'),
    messaging: require('./messages.png'),
    forums: require('./forums.png'),
    socialSharing: require('./socialSharing.png'),
    fbOpenGraph: require('./facebookOpenGraph.png'),
    pushNotifications: require('./pushNotifications.png'),
    subscriptions: require('./subscription.png'),
    shoppingCart: require('./shopping.png'),
    inAppPurchase: require('./inAppPurchasing.png'),
    paymentInformation: require('./paymentInformation.png'),
    paymentProcessing: require('./paymentProcessing.png'),
    marketPlace: require('./marketplace.png'),
    productManagement: require('./productManagement.png'),
    intercom: require('./intercom.png'),
    usageAnalytics: require('./usageAnalytics.png'),
    crashReporting: require('./crashReporting.png'),
    multilingualSupport: require('./multilingual.png'),
    cmsIntegration: require('./cms.png'),
    userAdminPages: require('./userAdminPage.png'),
    moderation: require('./moderation.png'),
    performanceMonitoring: require('./perfomanceMonitoring.png'),
    thirdParty: require('./connection.png'),
    smsMessaging: require('./sms.png'),
    phoneMasking: require('./maskingPhoneNumber.png'),
    api: require('./api.png'),
    twoFactorAuth: require('./twoFactorAuthentication.png'),
    ssl: require('./sslCertificate.png'),
    dos: require('./dosProtection.png'),
    callMe: require('./call.png'),
    emailMe: require('./email.png'),
    thankYou: require('./thankYou.svg'),
    DenovoLogo: require('./DenovoLogo.png')
};

export default images
