import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Main from './components/Main'
class App extends Component {
  render() {
    return (
     <Main/>
    );
  }
}

export default App;
