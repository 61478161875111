import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Option from './Option';
const MasterContainer = styled.div`
  background: #f2f6fc;
  width: 100%;
  padding: 50px;
  padding-top: 10px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding: 30px;
    padding-top: 10px;
  }
`;
const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 580px;
`;
const SectionTitle = styled.div`
  font-family: 'PTSansBold';
  text-align: center;
  font-size: 20px;
  margin-bottom: 30px;
  white-space: pre-line;
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

const SubSectionTitle = styled.div`
  font-family: 'PTSansRegular';
  text-align: center;
  font-size: 15px;
  margin: 10px;
  white-space: pre-line;
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

const ContainerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

class Section extends React.Component {
    render() {
        return (

            <MasterContainer>
                <SectionTitle>
                    {this.props.title}
                </SectionTitle>
                <SubSectionTitle>
                    {this.props.subtitle}
                </SubSectionTitle>
                <ContainerWrapper>

                <OptionsContainer>
                {this.props.options.map((option,index) =>
                    <Option key={index} option={option} toggleOption={this.props.toggleOption} selected={this.props.selected}/>
                )}
                </OptionsContainer>
                </ContainerWrapper>
            </MasterContainer>
        );
    }
}
Section.propTypes = {
    indexKey: PropTypes.number,
    options: PropTypes.array,
    selected: PropTypes.array
};
export default Section;
