import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Images from '../images/Images';
const MasterContainer = styled.div`
  border: ${props => props.selected ? "2px #1777CB solid" : "2px transparent solid"};
  background-color: ${props => props.selected? "#1777CB" : "white"};
  color: ${props => props.selected? "white" : "dimgrey"};
  border-radius: 5px;
  width: 250px;
  padding: 20px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20px;
  &:hover{
    cursor: pointer;
  }
  box-shadow: 5px 5px 5px rgba(0,0,0,0.10), 0 5px 5px rgba(0,0,0,0.10);
  position:relative;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    margin: 10px 0;
    justify-content: center;
  }
`;
const Icon = styled.div`
  width: 100px;
  height: 100px;
  background-image: url(${props => props.image});
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    width: 75px;
    height: 75px;
    margin-bottom: 0;
    margin-right: 20px;
  }
`;
const OptionName = styled.div`
  //font-weight: 500;
  font-family: 'PTSansBold';
  font-size: 14px;
  white-space: pre-line;
  text-align: center;
  padding:0 5px;
  @media (max-width: 768px) {
    text-align: center;
  }
`;
const DescriptionContainer = styled.div`
  background: #555;
  font-family: 'PTSansBold';
  padding: 10px;
  border-radius: 2px;
  position: absolute;
  top:calc(100% + 20px);
  width: 100%;
  white-space: pre-line;
  text-align: center;
  z-index: 2;
  color: white;
  &::after{
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #555 transparent;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
const OptionDescription = styled.div`
  display: none;
  font-family: 'PTSansRegular';
  font-size: 12px;
  white-space: pre-line;
  margin-top: 10px;
  @media (max-width: 768px) {
    display: block;
  }
`;
const OptionAndDescriptionContainer = styled.div`
  @media (max-width: 768px) {
    max-width: calc(100%);
  }
`;

const ContainerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color:blue;  
`;

class Option extends React.Component {
    constructor(props) {
        super(props);
        this.handleMouseHover = this.handleMouseHover.bind(this);
        this.state = {
            isHovering: false,
        };
    }
    findObjectByKey = (array, key, value) => {
        for (let i = 0; i < array.length; i++) {
            if (array[i][key] === value) {
                return array[i];
            }
        }
        return null;
    };
    handleMouseHover() {
        this.setState(this.toggleHoverState);
    }
    toggleHoverState(state) {
        return {
            isHovering: !state.isHovering,
        };
    }
    render() {
        return (
            <MasterContainer
                onMouseEnter={this.handleMouseHover}
                onMouseLeave={this.handleMouseHover}
                onClick={() => this.props.toggleOption(this.props.option)}
                selected={this.props.selected[this.props.option.section_number] && this.findObjectByKey(this.props.selected[this.props.option.section_number],'option', this.props.option.option)}>
                {/*<Icon image={Images[this.props.option.icon]}/>*/}
                <OptionAndDescriptionContainer>
                    <OptionName>{this.props.option.option}</OptionName>
                    {/*<OptionDescription>{this.props.option.description}</OptionDescription>*/}
                </OptionAndDescriptionContainer>
                {/*{this.state.isHovering &&
                    <DescriptionContainer>
                        {this.props.option.description}
                    </DescriptionContainer>
                }*/}
            </MasterContainer>
        );
    }
}
Option.propTypes = {
    indexKey: PropTypes.number,
    option: PropTypes.object,
    toggleOption: PropTypes.func,
    selected: PropTypes.array
};
export default Option;
