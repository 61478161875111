import React from 'react';
import styled from 'styled-components';
import * as Papa from 'papaparse';
import Section from './Section';
import Images from '../images/Images';
import 'react-phone-number-input/style.css';
import axios from 'axios';
import ProgressBar from "./progress-bar.component";
import LogoLoader from '../images/animated-video-denovo.gif';

const MasterContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
`;
const ContentContainer = styled.div`
  max-width: 1170px;
  width: 100%;
`;
const SlidesContainer = styled.div`
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f2f6fc;
  padding-bottom: 100px;
`;
const Slide = styled.div`
  width: 100%;
  visibility: ${props => props.visible ? 'visible' : 'hidden'};
  opacity: ${props => props.visible ? 1 : 0};
  height: ${props => props.visible ? 'initial' : '0px'};
  transition: visibility 0s, opacity 0.5s ease;
  display: flex;
`;
const ContainerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
const SelectPlatformContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 580px;
`;
const PlatformContainer = styled.div`
  border: ${props => props.selected ? "2px #1777CB solid" : "2px transparent solid"};
  background-color: ${props => props.selected ? "#1777CB" : "white"};
  color: ${props => props.selected ? "white" : "dimgrey"};
  border-radius: 5px;
  width: 250px;  
  padding: 20px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20px;
  position: relative;
  &:hover{
    cursor: pointer;
  }
  box-shadow: 5px 5px 5px rgba(0,0,0,0.10), 0 5px 5px rgba(0,0,0,0.10);
  }
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    margin: 10px 0;
    justify-content: center;
  }
`;
const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const ReserveYourSpotButton = styled.div`
  font-family: 'PTSansBold';
  background: black;
  padding:10px 60px;
  margin: 10px;
  display: block; 
  border-radius: 30px;
  box-shadow: 0 0px, 0 0px;
  color: white;
  font-weight: bold;
  &:hover{
    cursor: pointer;
  }
  margin-top: 100px;
`;

const NextButton = styled.div`
  font-family: 'PTSansBold';
  background: black;
  padding:10px 60px;
  margin: 10px;
  display: ${props => props.show ? 'block' : 'none'};
  border-radius: 30px;
  box-shadow: 0 0px, 0 0px;
  color: white;
  font-weight: bold;
  &:hover{
    cursor: pointer;
  }
  margin-top: 0px;
`;
const BackButton = styled(NextButton)`
  background: #BCC0C5;
  box-shadow: 0 0px, 0 0px;
  color: #676767;
`;
const FirstSection = styled.div`
  background: #f2f6fc;
  width: 100%;
  padding: 50px;
  padding-top: 10px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding: 30px;
    padding-top: 10px;
  }
`;
const FirtsSectionTitle = styled.div`
  font-family: 'PTSansBold';
  text-align: center;
  font-size: 20px;
  margin-bottom: 30px;
  white-space: pre-line;
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;
const FirstSubSectionTitle = styled.div`
  font-family: 'PTSansRegular';
  text-align: center;
  font-size: 15px;
  margin: 10px;
  white-space: pre-line;
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;
const ContactTitle = styled.div`
  font-family: 'PTSansBold';
  text-align: center;
  font-size: 20px;
  margin-bottom: 40px;
  white-space: pre-line;
`;
const SmallIconContainer = styled.div`
  width: 50px;
  height: 50px;
  background-image: url(${props => props.image});
  background-color: ${props => props.selected ? '#acacac' : '#f2f6fc'};
  background-size: 40%;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  border: 2px #acacac solid;
  border-radius: 50%;
  margin: 20px;
`;
const FirstOptionsName = styled.div`
  font-family: 'PTSansBold';
  font-size: 14px;
  white-space: pre-line;
  text-align: center;
  @media (max-width: 768px) {
    text-align: center;
  }
`;
const LastScreen = styled.div`
  margin: 0 auto;
  padding: 100px;
  font-family: 'PTSansBold';
  text-align: center;
  width: 100%;
  @media (max-width: 768px) {
    padding: 30px;
  }
  padding-bottom: 0;
`;
const CustomInput = styled.div`
  border: 1px solid black;
  padding: 10px 30px;
  border-radius: 5px;
  display: flex;
  background-color: white;
  input{
    color: black;
    font-family: 'PTSansRegular';
    border: none;
    background-color: white !important;
    box-shadow: none!important;
    width: 100%;
  }
`;
const ErrorMessage = styled.div`
  margin-top: 0px;
  margin-bottom: 10px;
  text-align: center;
  height: 20px;
  font-family: 'PTSansRegular';
  color:#D6041D ;
  @media (max-width: 768px) {
    margin-top: 0px;
  }
`;
const OptionAndDescriptionContainer = styled.div`
  @media (max-width: 768px) {
    max-width: calc(100%);
  }
`;
const LoadingScreen = styled.div`
  background: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ThankYouScreen = styled.div`
  margin: 0 120px;
  font-family: 'PTSansBold';
  text-align: center;
  width: 100%;
  @media (max-width: 768px) {
    margin: 0px;
  }
`;
const ThankYouImage = styled.div`
  background-image: url(${props => props.image});
  width: 400px;
  height: 400px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  margin: 50px auto;
  @media (max-width: 800px) {
    width: 300px;
    height: 300px;
  }
  @media (max-width: 600px) {
    width: 200px;
    height: 200px;
  }
`;
const ThankYouParagraph = styled.p`
font-family: 'PTSansRegular';
  text-align: center;
  font-size: 15px;
  margin: 10px;
  white-space: pre-line;
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

const HyperlinkContainer = styled.div`
    text-align: center;
`;
const HyperLinks = styled.a`
  font-size: 16px;
  font-weight: normal;
  line-height: 2;
  width: 70%
  margin: 0 auto;
  white-space: normal;
  display: block;
`;

const LogoContainer = styled.a`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const LogoImage = styled.img`
    height: 70px;
    cursor: pointer;
`;

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            webSections: [],
            mobileSections: [],
            options: [],
            chosenOptions: [],
            //platform: 'ios/android',
            loaded: false,
            sum: 0,
            currentSlide: 0,
            reachOption: 'mail',
            phone: '',
            mail: '',
            mailErrorMessage: '',
            phoneErrorMessage: '',
            hybridIsHovering: false,
            webIsHovering: false,
            iosIsHovering: false,
            androidIsHovering: false,
            minCurrentRate: 100,
            maxCurrentRate: 100,
            progresspercentage: 0,
            sliderError: false
        };
      

        let csvSectionsPath = require('../settings/sectionsV2.csv');
        Papa.parse(csvSectionsPath, {
            header: true,
            download: true,
            skipEmptyLines: true,
            complete: this.createSections
        });
        this.containerRef = null;
    }

    handleMouseHover = (platform) => {
        let hoverKey = platform + 'IsHovering';
        this.setState(this.toggleHoverState(hoverKey));
    };
    toggleHoverState = (hoverKey) => {
        // console.log('hover Key', hoverKey)
        return {
            [hoverKey]: !this.state[hoverKey],
        };
    };
    createSections = (result) => {
        const data = result.data;
        let webSections = [];
        let mobileSections = [];
        data.forEach((item) => {
            item.options = [];
            let webItem = { ...item };
            let mobItem = { ...item };
            if (!item.mobile_only) {
                webSections.push(webItem)
            }
            mobileSections.push(mobItem)
        });
        // console.log(webSections, mobileSections)
        this.getOptions(webSections, mobileSections)
    };
    getOptions(webSections, mobileSections) {
        let csvOptionsPath = require('../settings/optionsV2.csv');
        Papa.parse(csvOptionsPath, {
            header: true,
            download: true,
            skipEmptyLines: true,
            complete: (result) => this.populateWithOptions(result.data, webSections, mobileSections)
        });
    }

    populateWithOptions(data, webSections, mobileSections) {
        let mobileOptionsBySections = [];
        let webOptionsBySections = [];
    
        data.forEach((item) => {
            item.mobile_duration = parseInt(item.mobile_duration, 10); // Ensure mobile_duration is stored as an integer
            item.web_duration = parseInt(item.web_duration, 10); // Ensure web_duration is stored as an integer

                if (!mobileOptionsBySections[item.section_number]) {
                mobileOptionsBySections[item.section_number] = [];
                webOptionsBySections[item.section_number] = [];
            }
            if (!item.web_only) {
                mobileOptionsBySections[item.section_number].push(item);
            }
            if (!item.mobile_only) {
                webOptionsBySections[item.section_number].push(item);
            }
        });
    
        webSections.forEach((item) => {
            item.options = webOptionsBySections[item.section_number];
        });
        mobileSections.forEach((item) => {
            item.options = mobileOptionsBySections[item.section_number];
        });
    
        this.setState({
            mobileSections: mobileSections,
            webSections: webSections,
            mobileSelected: [],
            webSelected: [],
            loaded: true,
            optionsData: data // Store the CSV data in the state
        });
    }

    /*populateWithOptions = (result, webSections, mobileSections) => {
        let mobileOptionsBySections = [];
        let webOptionsBySections = [];
        // console.log('result', result.data)
        result.data.forEach((item) => {
            if (!mobileOptionsBySections[item.section_number]) {
                mobileOptionsBySections[item.section_number] = [];
                webOptionsBySections[item.section_number] = [];
            }
            if (!item.web_only) {
                mobileOptionsBySections[item.section_number].push(item)
            }
            if (!item.mobile_only) {
                webOptionsBySections[item.section_number].push(item)
            }
        });
        webSections.forEach((item) => {
            item.options = webOptionsBySections[item.section_number]
        });
        mobileSections.forEach((item) => {
            item.options = mobileOptionsBySections[item.section_number]
        });
        // console.log(webSections)
        this.setState({
            mobileSections: mobileSections,
            webSections: webSections,
            mobileSelected: [],
            webSelected: [],
            loaded: true
        })
    };*/
    togglePlatform = (platform) => {
        let selectedPlatform = platform;
        if (platform === 'ios' && this.state.platform === 'android') {
            selectedPlatform = 'ios/android';
        }
        if (platform === 'android' && this.state.platform === 'ios') {
            selectedPlatform = 'ios/android';
        }
        {
            if (platform === 'web' && this.state.platform === 'ios/android') {
                selectedPlatform = 'ios/android/web';
            }
        }
        if (platform === 'ios' && this.state.platform === 'web') {
            selectedPlatform = 'ios/web';
        }
        if (platform === 'web' && this.state.platform === 'ios') {
            selectedPlatform = 'ios/web';
        }
        if (platform === 'android' && this.state.platform === 'web') {
            selectedPlatform = 'android/web';
        }
        if (platform === 'web' && this.state.platform === 'android') {
            selectedPlatform = 'android/web';
        }
        if (platform === 'hybrid' && this.state.platform === 'web') {
            selectedPlatform = 'hybrid/web';
        }
        if (platform === 'web' && this.state.platform === 'hybrid') {
            selectedPlatform = 'hybrid/web';
        }
        if (platform === 'ios' && this.state.platform === 'android/web') {
            selectedPlatform = 'ios/android/web';
        }
        if (platform === 'android' && this.state.platform === 'ios/web') {
            selectedPlatform = 'ios/android/web';
        }
        //to deselect
        if (platform === 'web' && this.state.platform === 'ios/android/web') {
            selectedPlatform = 'ios/android';
        }
        if (platform === 'web' && this.state.platform === 'android/web') {
            selectedPlatform = 'android';
        }
        if (platform === 'web' && this.state.platform === 'ios/web') {
            selectedPlatform = 'ios';
        }
        if (platform === 'ios' && this.state.platform === 'ios/android/web') {
            selectedPlatform = 'android/web';
        }
        if (platform === 'ios' && this.state.platform === 'ios/web') {
            selectedPlatform = 'web';
        }
        if (platform === 'ios' && this.state.platform === 'ios/android') {
            selectedPlatform = 'android';
        }
        if (platform === 'android' && this.state.platform === 'ios/android/web') {
            selectedPlatform = 'ios/web';
        }
        if (platform === 'android' && this.state.platform === 'android/web') {
            selectedPlatform = 'web';
        }
        if (platform === 'android' && this.state.platform === 'ios/android') {
            selectedPlatform = 'ios';
        }
        if (platform === 'web' && this.state.platform === 'hybrid/web') {
            selectedPlatform = 'hybrid';
        }
        if (platform === 'hybrid' && this.state.platform === 'hybrid/web') {
            selectedPlatform = 'web';
        }

        this.state.chosenOptions[0] = selectedPlatform;

        this.setState({
            sliderError: false,
            platform: selectedPlatform,
            mobile: platform !== 'web'
        }, () => console.log(this.state))
    };

    toggleOption = (option) => {
        console.log('option', option, this.state.platform);
        let checkObject = this.state.platform === 'web' ? this.state.webSections : this.state.mobileSections;
        let selectedObject = this.state.platform === 'web' ? this.state.webSelected : this.state.mobileSelected;
    
        let selectedSection = this.findObjectByKey(checkObject, 'section_number', option.section_number);
        let isMultiSelect = selectedSection.multiselect;
    
        // Initialize chosenOptions if not already set
        if (!this.state.chosenOptions[selectedSection.section_number]) {
            this.state.chosenOptions[selectedSection.section_number] = [];
        }
    
        if (isMultiSelect) {
            // Handle multi-select
            let chosenOptions = [...this.state.chosenOptions[selectedSection.section_number]];
            const optionIndex = chosenOptions.indexOf(option.option);
            if (optionIndex > -1) {
                chosenOptions.splice(optionIndex, 1); // Deselect option if already selected
            } else {
                chosenOptions.push(option.option); // Add option if not selected
            }
            this.state.chosenOptions[selectedSection.section_number] = chosenOptions;
        } else {
            // Handle single-select
            this.state.chosenOptions[selectedSection.section_number] = [option.option];
        }
    
        console.log('test test ' + this.state.chosenOptions);
    
        if (isMultiSelect) {
            // Handle multi-select
            if (!selectedObject[selectedSection.section_number]) {
                selectedObject[selectedSection.section_number] = [];
            }
            const optionIndex = selectedObject[selectedSection.section_number].findIndex(o => o.option === option.option);
            if (optionIndex > -1) {
                selectedObject[selectedSection.section_number].splice(optionIndex, 1); // Deselect option if already selected
            } else {
                selectedObject[selectedSection.section_number].push(option); // Add option if not selected
            }
        } else {
            // Handle single-select
            selectedObject[selectedSection.section_number] = [option];
        }
    
        if (this.state.platform === 'web') {
            this.setState({
                webSelected: selectedObject,
                sliderError: false,
            });
        } else {
            this.setState({
                mobileSelected: selectedObject,
                sliderError: false,
            });
        }
    };
    /*toggleOption = (option) => {
        console.log('option', option, this.state.platform)
        let checkObject = this.state.webSections;
        let selectedObject = this.state.webSelected;
        let web = true;

        if (this.state.platform !== 'web') {
            checkObject = this.state.mobileSections;
            selectedObject = this.state.mobileSelected;
            web = false
        }

        let selectedSection = this.findObjectByKey(checkObject, 'section_number', option.section_number)
        let isMultiSelect = selectedSection.multiselect;

        this.state.chosenOptions[parseInt(selectedSection.section_number)] = option.option;

        console.log('test test ' + this.state.chosenOptions);

        if (selectedObject[selectedSection.section_number] && this.findObjectByKey(selectedObject[selectedSection.section_number], 'option', option.option)) {
            return
        }
        selectedObject[selectedSection.section_number] = [];
        selectedObject[selectedSection.section_number].push(option);
        if (web) {
            this.setState({
                webSelected: selectedObject,
                sliderError: false,
            });
        }
        this.setState({
            mobileSelected: selectedObject,
            sliderError: false,
        });
    };*/

    
    calculateSum = (callback) => {
        const PLATFORM_HOURS = {
            ios: 100,
            android: 100,
            web: 120,
            hybrid: 180
        };
    
        const { chosenOptions, optionsData, platform } = this.state;
        let sum = 0;
    
        console.log('Chosen Options:', chosenOptions);
        console.log('Options Data:', optionsData);
        console.log('Selected Platform:', platform);
    
        // Check if the project type includes "New application" or "Migration or porting of an application"
        const projectType = chosenOptions[1] || [];
        console.log('Project Type:', projectType);
    
        const includePlatformHours = projectType.some(type => 
            type.trim().toLowerCase() === 'new application' || 
            type.trim().toLowerCase() === 'migration or porting of an application'
        );
    
        console.log('includePlatformHours:', includePlatformHours);
        console.log('platform:', platform);
    
        // Add platform hours if the condition is met
        if (includePlatformHours && platform) {
            const platforms = platform.split('/');
    
            platforms.forEach(plat => {
    
                if (PLATFORM_HOURS[plat]) {
                    console.log(`Adding ${PLATFORM_HOURS[plat]} hours for platform: ${plat}`);
                    sum += PLATFORM_HOURS[plat];
                }
            });
        }
    
        // Add other options' hours
        Object.keys(chosenOptions).forEach(section => {
            if (section !== 'platform' && Array.isArray(chosenOptions[section])) { // Ensure it's an array
                chosenOptions[section].forEach(option => {
                    console.log(`Looking for section: ${section}, option: ${option}`);
                    // Find the corresponding CSV data for the option
                    const optionData = optionsData.find(item => {
                        console.log(`Comparing with item:`, item);
                        return parseInt(item.section_number) === parseInt(section) && item.option.trim() === option.trim();
                    });
                    if (optionData) {
                        const duration = platform.includes('web') ? optionData.web_duration : optionData.mobile_duration;
                        console.log(`Adding ${duration} hours for option: ${option}`);
                        sum += duration; // Use the stored duration values
                    } else {
                        console.log(`No matching option found for section: ${section}, option: ${option}`);
                    }
                });
            }
        });
    
        console.log('Total Sum:', sum);
        this.setState({ sum }, () => {
            if (callback) callback(sum);
        });
    };

    findObjectByKey = (array, key, value) => {
        for (let i = 0; i < array.length; i++) {
            if (array[i][key] === value) {
                return array[i];
            }
        }
        return null;
    };
    getKeyOfObject = (array, key, value) => {
        for (let i = 0; i < array.length; i++) {
            if (array[i][key] === value) {
                return i;
            }
        }
        return null;
    };

    showSlideError = () => {

        if (this.state.chosenOptions[this.state.currentSlide] || this.state.currentSlide >= this.state.webSections.length + 1) {
            this.setState({
                sliderError: false
            });
            return false;
        }
        else {
            this.setState({
                sliderError: true
            });
            return true;
        }
    }

    goToNextSlide = () => {
        if (this.showSlideError()) {
            return;
        }

        const { currentSlide } = this.state;
        let nextSlide = currentSlide + 1;
        if (this.state.platform === 'web' && (currentSlide === 1 || currentSlide === 2) && this.state.webSelected[currentSlide] === undefined) {
            return;
        }
        if (this.state.platform !== 'web' && (currentSlide === 1 || currentSlide === 2) && this.state.mobileSelected[currentSlide] === undefined) {
            return;
        }
        this.setState({
            currentSlide: nextSlide,
        });
        window.scrollTo(0, this.containerRef.offsetTop)
    };
    goToPrevSlide = () => {
        const { currentSlide } = this.state;
        let nextSlide = currentSlide - 1;
        this.setState({
            currentSlide: nextSlide,
        });
        window.scrollTo(0, this.containerRef.offsetTop)
    };
    goToSpecificSlide = (slideNo) => {
        this.setState({
            currentSlide: slideNo
        });
        window.scrollTo(0, this.containerRef.offsetTop)
    };
    changeReachOption = (option) => {
        this.setState({
            reachOption: option
        })
    };
    validateEmail = (email) => {
        let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };
    reserveYourSpot = () => {
        let _this = this;
        if (!this.state.mail) {
            _this.setState({
                mailErrorMessage: 'Please enter your email address'
            })
            return;
        }
        if (!_this.validateEmail(this.state.mail)) {
            _this.setState({
                mailErrorMessage: 'Please enter a valid email'
            });
            return;
        }

        this.submitRequest();

    };
    submitRequest = () => {
        this.calculateSum((sum) => {
            console.log('Calculated Sum:', sum);
            
            axios.post('https://us-central1-app-cost-estimator-75b39.cloudfunctions.net/emailMessage', {
                //axios.post('http://127.0.0.1:5001/app-cost-estimator-75b39/us-central1/emailMessage', {
                platform: this.state.chosenOptions[0],
                projectType: this.state.chosenOptions[1],
                coreFeatures: this.state.chosenOptions[2],
                designRequirements: this.state.chosenOptions[3],
                estimatedUsers: this.state.chosenOptions[4],
                securityCompliance: this.state.chosenOptions[5],
                projectTimeline: this.state.chosenOptions[6],
                ongoingMaintenance: this.state.chosenOptions[7],
                email: this.state.mail,
                estimatedCost: sum // Pass the calculated sum
            })
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            });

            this.goToNextSlide();
        });
    };

    render() {
        const { currentSlide } = this.state;
        return (
            <MasterContainer>
                {!this.state.loaded ?
                    <LoadingScreen>
                        <img src={LogoLoader} alt="Loading..." />
                    </LoadingScreen>
                    :
                    <ContentContainer ref={(ref) => this.containerRef = ref}>
                        <LogoContainer>
                            <LogoImage src={Images.DenovoLogo} alt="Logo" onClick={()=> window.open("https://www.denovostudios.com", "_blank")}/>
                        </LogoContainer>
                        <SlidesContainer>
                            <ProgressBar bgcolor={"#1777CB"} completed={(currentSlide / (this.state.webSections.length)) * 100} isVisible={currentSlide < this.state.webSections.length + 1} />
                            <Slide visible={currentSlide === 0}>
                                <FirstSection>
                                    <FirtsSectionTitle>
                                        Which platform(s) will you use for your app?
                                    </FirtsSectionTitle>
                                    <FirstSubSectionTitle>
                                        Identifying the right platform is an essential part of your app development process. Choose the platform that you wish to develop your app in or the platform you are looking to continue development for.
                                    </FirstSubSectionTitle>
                                    <ContainerWrapper>
                                        <SelectPlatformContainer >
                                            <PlatformContainer
                                                onMouseEnter={() => this.handleMouseHover('ios')}
                                                onMouseLeave={() => this.handleMouseHover('ios')}
                                                selected={this.state.platform === 'ios' || this.state.platform === 'ios/android' || this.state.platform === 'ios/android/web' || this.state.platform === 'ios/web'}
                                                onClick={() => this.togglePlatform('ios')}
                                            >
                                                <OptionAndDescriptionContainer>
                                                    <FirstOptionsName>iOS</FirstOptionsName>
                                                </OptionAndDescriptionContainer>
                                            </PlatformContainer>
                                            <PlatformContainer
                                                onMouseEnter={() => this.handleMouseHover('android')}
                                                onMouseLeave={() => this.handleMouseHover('android')}
                                                selected={this.state.platform === 'android' || this.state.platform === 'ios/android' || this.state.platform === 'ios/android/web' || this.state.platform === 'android/web'}
                                                onClick={() => this.togglePlatform('android')}
                                            >
                                                <OptionAndDescriptionContainer>
                                                    <FirstOptionsName>Android</FirstOptionsName>
                                                </OptionAndDescriptionContainer>
                                            </PlatformContainer>
                                            <PlatformContainer
                                                onMouseEnter={() => this.handleMouseHover('hybrid')}
                                                onMouseLeave={() => this.handleMouseHover('hybrid')}
                                                selected={this.state.platform === 'hybrid' || this.state.platform === 'hybrid/web'}
                                                onClick={() => this.togglePlatform('hybrid')}
                                            >
                                                <OptionAndDescriptionContainer>
                                                    <FirstOptionsName>Cross Platform (iOS and Android)</FirstOptionsName>
                                                </OptionAndDescriptionContainer>
                                            </PlatformContainer>
                                            <PlatformContainer
                                                onMouseEnter={() => this.handleMouseHover('web')}
                                                onMouseLeave={() => this.handleMouseHover('web')}
                                                selected={this.state.platform === 'web' || this.state.platform === 'hybrid/web' || this.state.platform === 'ios/web' || this.state.platform === 'android/web' || this.state.platform === 'ios/android/web'}
                                                onClick={() => this.togglePlatform('web')}
                                            >
                                                <OptionAndDescriptionContainer>
                                                    <FirstOptionsName>Other Custom Software (APIs, web app, website, chatbot, etc)</FirstOptionsName>
                                                </OptionAndDescriptionContainer>
                                            </PlatformContainer>
                                        </SelectPlatformContainer>
                                    </ContainerWrapper>
                                </FirstSection>
                            </Slide>
                            {this.state.platform === 'web' ?
                                this.state.webSections.map((section, index) =>
                                    <Slide visible={currentSlide === index + 1} key={index}>
                                        <Section title={section.section_name} subtitle={section.section_subtitle}
                                            indexKey={index}
                                            options={section.options}
                                            toggleOption={this.toggleOption}
                                            selected={this.state.webSelected}
                                        />
                                    </Slide>
                                )
                                :
                                this.state.mobileSections.map((section, index) =>
                                    <Slide visible={currentSlide === index + 1} key={index}>
                                        <Section title={section.section_name} subtitle={section.section_subtitle}
                                            indexKey={index}
                                            options={section.options}
                                            toggleOption={this.toggleOption}
                                            selected={this.state.mobileSelected} />
                                    </Slide>
                                )
                            }

                            {this.state.platform === 'web' ?
                                <Slide visible={currentSlide === this.state.webSections.length + 1}>
                                    <LastScreen>
                                        <ContactTitle>
                                            Please enter your email address where we can send you the quote.
                                        </ContactTitle>
                                        <CustomInput>
                                            <input
                                                className="react-phone-number-input__input"
                                                placeholder="Your email address"
                                                value={this.state.mail}
                                                onChange={event => this.setState({ mail: event.target.value, mailErrorMessage: '' })}
                                                type='email'
                                            />
                                        </CustomInput>
                                        <div style={{ height: '20px' }}>
                                            {this.state.mailErrorMessage !== '' &&
                                                <ErrorMessage>
                                                    {this.state.mailErrorMessage}
                                                </ErrorMessage>
                                            }
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <ReserveYourSpotButton onClick={this.reserveYourSpot}>
                                                Get my estimate
                                            </ReserveYourSpotButton>
                                        </div>
                                    </LastScreen>
                                </Slide>
                                :
                                <Slide visible={currentSlide === this.state.mobileSections.length + 1}>
                                    <LastScreen>
                                        <ContactTitle>
                                            Please enter your email address where we can send you the quote.
                                        </ContactTitle>
                                        <CustomInput>
                                            <input
                                                className="react-phone-number-input__input"
                                                placeholder="Your email address"
                                                value={this.state.mail}
                                                onChange={event => this.setState({ mail: event.target.value, mailErrorMessage: '' })}
                                                type='email'
                                            />
                                        </CustomInput>
                                        <div style={{ height: '20px' }}>
                                            {this.state.mailErrorMessage !== '' &&
                                                <ErrorMessage>
                                                    {this.state.mailErrorMessage}
                                                </ErrorMessage>
                                            }
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <ReserveYourSpotButton onClick={this.reserveYourSpot}>
                                                Get my estimate
                                            </ReserveYourSpotButton>
                                        </div>
                                    </LastScreen>
                                </Slide>
                            }

                            {this.state.platform === 'web' ?
                                <Slide visible={currentSlide === this.state.webSections.length + 2}>
                                    <ThankYouScreen>
                                        <ThankYouParagraph>
                                            Thanks for using our app cost estimator. You'll be receiving your quote momentarily.
                                        </ThankYouParagraph>
                                        <FirstSubSectionTitle>
                                            Here are some useful links:
                                        </FirstSubSectionTitle>
                                        <HyperlinkContainer>
                                            <HyperLinks href='https://denovostudios.com'>Learn about our development company</HyperLinks>
                                            <HyperLinks href='https://denovostudios.com/app-development'>Read about our app development services</HyperLinks>
                                            <HyperLinks href='https://denovostudios.com/enterprise-app-development'>Enterprise app development</HyperLinks>
                                            <HyperLinks href='https://denovostudios.com/game-development'>Game and Unity development</HyperLinks>
                                        </HyperlinkContainer>
                                    </ThankYouScreen>
                                </Slide>
                                :
                                <Slide visible={currentSlide === this.state.mobileSections.length + 2}>
                                    <ThankYouScreen>
                                        <ThankYouParagraph>
                                            Thanks for using our app cost estimator. You'll be receiving your quote momentarily.
                                        </ThankYouParagraph>
                                        <FirstSubSectionTitle>
                                            Here are some useful links:
                                        </FirstSubSectionTitle>
                                        <HyperlinkContainer>
                                            <HyperLinks href='https://denovostudios.com'>Learn about our development company</HyperLinks>
                                            <HyperLinks href='https://denovostudios.com/app-development'>Read about our app development services</HyperLinks>
                                            <HyperLinks href='https://denovostudios.com/enterprise-app-development'>Enterprise app development</HyperLinks>
                                            <HyperLinks href='https://denovostudios.com/game-development'>Game and Unity development</HyperLinks>
                                        </HyperlinkContainer>
                                    </ThankYouScreen>
                                </Slide>
                            }
                            <ButtonContainer>
                                <BackButton onClick={this.goToPrevSlide} show={currentSlide !== 0 && (this.state.platform === 'web' ? currentSlide < this.state.webSections.length + 1 : currentSlide < this.state.mobileSections.length + 1)}>
                                    BACK
                                </BackButton>
                                <NextButton onClick={this.goToNextSlide} show={this.state.platform === 'web' ? currentSlide < this.state.webSections.length + 1 : currentSlide < this.state.mobileSections.length + 1}>
                                    NEXT
                                </NextButton>
                            </ButtonContainer>
                            {this.state.sliderError === true &&
                                <ErrorMessage>
                                    {"Please select an option"}
                                </ErrorMessage>
                            }


                        </SlidesContainer>
                    </ContentContainer>
                }
            </MasterContainer>
        );
    }
}
export default Main;
